.container {
    display: flex;
    justify-content: space-around;
    gap: 1rem;

    & select {
        font-size: 1.2rem;
    }

    & span {
        font-size: 1.2rem;
        color: #878787;
        align-self: center;
    }
}