@import '../../assets/styles/variables.module.scss';
@import '../../assets//styles/mixins.module.scss';

.container {
    @include page-container-default;
    grid-row: 1/3;
    padding: 0.5rem;
}

.main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // margin-bottom: 2rem;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    padding: 1rem 1rem;
}

.main_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include gray-bottom-border;
}

.main_top_extra_padding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // padding: 1rem 0rem;
    margin: 1rem 0rem;
    // @include gray-bottom-border;
}


.main_title {
    @include page-title
}

.items_selected_div{
    font-size: 1.2rem;
    font-weight: 600;
    color: $gray-primary;
    // margin-top: 1rem;
}


.main_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.main_operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}


.filter {
    display: flex;
    gap: 1rem;
}

.filter_checkboxes {
    display: flex;
    gap: 1rem;
    border-style: solid;
    border-width: 2px;
    border-color: $blue-primary;
    padding: 0rem 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter_checkboxes_item {
    font-size: 1.4rem;
    font-weight: 500;
    color: $blue-primary;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.table_data {
    & tr:hover {
        background-color: $background-color-all-pages;
    }
    & td {
        padding: 0.5rem;
        border: 1px solid rgb(230, 230, 230);
        
        max-width: 200px;
        min-height: 100px;
        max-height: 100px;
        text-align: center;
        overflow: scroll;

        gap: 1rem;

    }
    & td img{
        max-width: 150px;
        max-height: 100px;
    }
    & td div{
        display: flex;
        justify-content: center;
        align-items: center;

        gap:1rem;
        max-height: 100px;
    }

    & tr:last-child td div{
        top: -15rem ;
    }

    & tr:nth-last-child(2) td div {
        top: -15rem;
    }

    & tr:nth-last-child(1) td div {
        top: -15rem;
    }

    & tr:first-child td div {
        top: 5rem !important;
    }

    & tr:nth-child(2) td div {
        top: 5rem !important;
    }

    & tr:nth-child(3) td div {
        top: 5rem !important;
    }
}

.custom_name_field{
    max-width: 200px;
}


.main_bottom {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.action_tab_buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:1.5rem;
}


.input_date{
    background-color: #f3f2f2;
    color: $color-foreshop;
    @include gray-bottom-border;
    padding:0.5rem;

    border-radius: 0.5rem;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    border-width: 1px;
}

.input_date:focus{
    border-color: rgb(230, 230, 230);

}

.action_button_each{
    cursor: pointer;
    margin: 0rem 0.5rem;
}

.action_button_each_inactive{
    margin: 0rem 0.5rem;
}

.action_button_each_progress{
    cursor: progress;
    margin: 0rem 0.5rem;
}

.action_button_each_send_now{
    cursor: pointer;
    width: 12%;
    height: auto;
}

.action_button_each_send_now_sending{
    cursor: progress;
    width: 12%;
    height: auto;
}

.action_button_each_website{
    cursor: pointer;
    padding: 0rem .5rem;
    margin: 0rem 1rem
}
.action_button_each_website_cursor_auto{
    cursor: auto;
    padding: 0rem .5rem;
    margin: 0rem 1rem
}

.no_data_found_div{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: $red-primary;
    margin: 1rem;
    padding: 0.5rem;
}

a:link {
    color: black;;
    text-decoration: none;
  }
  
  a:visited {
    color: black;;
    text-decoration: none;
  }
  
  a:hover {
    color: black;;
    text-decoration: none;
  }





// ######################################################## designs for website control ########################################################
.table_data_website {
    & tr:hover {
        background-color: $background-color-all-pages;
    }

    & td {
        padding: 0.5rem;
        border: 1px solid rgb(230, 230, 230);
        max-width: 150px;
        height: 100px;
        max-height: 100px !important;
        text-align: center;
        overflow: scroll;
    }
    & td img{
        max-width: 150px;
        max-height: 100px;
    }
    & td div{
        display: flex;
        justify-content: center;
        align-items: center;

        gap:1rem;
        max-height: 100px;
    }

    & tr:last-child td div{
        top: -15rem ;
    }

    & tr:nth-last-child(2) td div {
        top: -15rem;
    }

    & tr:nth-last-child(1) td div {
        top: -15rem;
    }

    & tr:first-child td div {
        top: 5rem !important;
    }

    & tr:nth-child(2) td div {
        top: 5rem !important;
    }

    & tr:nth-child(3) td div {
        top: 5rem !important;
    }
}

.formAlertDiv{
    color: $red-primary;
  }
  

.creative_image_section{
    width: 100%;
}

.fixed_height_div{
    min-width: 80vw;
    width: 80vw;

    height: 82vh;
    min-height: 82vh;
    max-height: 82vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    padding-top: 1rem;

    overflow: scroll;
}

.clipboard_div{
    min-width: 70vw;
    width: 70vw;

    height: 75vh;
    min-height: 75vh;
    max-height: 75vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 1rem;
 
    overflow: scroll;

}
.clipboard_div_items{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: s;
    align-items: center;

    gap: 0.5rem;
}

.clipboard_div_top{
    width: 100%;

    padding: 0rem 1rem;
    font-size: 1.3rem;

}
.bg_input_div{
    background-color: $input_background;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.clipboard_div_middle{
    width: 100%;
    min-height: 400px;

    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1.2rem;

    background-color: $input_background;

    overflow: scroll;
    position: relative;


}
.clipboard_div_bottom{
    width: 100%;

}
.middleAlert{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    color: white;
    background-color: #44ee1ee9;
    box-shadow: #47f91fe7 0px 5px 30px 0px;
    border-radius: 0.5rem;

    font-weight: 600;
    padding: 1rem;
    cursor: pointer;
    
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.big_text{
    font-size: 1.4rem;
}

.upload_div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    
    width: 100%;
    height: 100%;
    background-color: $input_background; 
    overflow-y: scroll;

    padding: 1rem;
    border-radius: 0.5rem;

}

.each_upload_div{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    // gap: 0.5rem;

}

.upload_label{
    font-size: 1.4rem;
    font-weight: 800;
    color: $blue-primary;
    padding: 0.5rem 0rem;
}

.red_text{
    color: $red-primary;
}

.upload_input{
    max-width: 100%;
    min-width: 100%;
    
    min-height: 150px;
    max-height: 150px;

    font-size: 1.4rem;

    background-color: white;
    color: $gray-primary;
    @include gray-bottom-border;
    padding:1rem;

    border-radius: 0.5rem;
    border: 1px solid white;

}

.upload_input_des{
    max-width: 100%;
    min-width: 100%;
    
    min-height: 80px;
    max-height: 80px;

    font-size: 1.4rem;

    background-color: white;
    color: $gray-primary;
    @include gray-bottom-border;
    padding:1rem;

    border-radius: 0.5rem;
    border: 1px solid white;


}

.upload_input_short{
    max-width: 100%;
    min-width: 100%;
    
    min-height: 50px;
    max-height: 50px;

    font-size: 1.4rem;

    background-color: white;
    color: $gray-primary;
    @include gray-bottom-border;
    padding:1rem;

    border-radius: 0.5rem;
    border: 1px solid white;

}

.each_edit_div{
    width: 100%;
    padding: 0.5rem 0rem;

    display: flex;
    flex-direction: row;
}

.each_edit_div_column{
    width: 100%;
    padding: 0rem;

    display: flex;
    flex-direction: column;

}

.each_edit_div_left{
    width: 70%;
    max-width: 70%;

    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;

}
.each_edit_div_right{
    width: 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;


}

.white_bg{
    background-color: $input_background !important;
}



.each_edit_input{
    width: 100%;
    height: 50px;
    padding: 1rem;
    color: $gray-primary;

    border-radius: 0.5rem;
    border: 1px solid white;
}


.add_images_btn_div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-top: 0.5rem;
    gap:1rem;

}

.add_images_btn_div_each{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}

.add_images_btn_div_each_right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

}

.alert_div{
    width: 200px;
}

.favicon_icon{
    width: 40%;
}



// ######################################################## designs for automation tab ########################################################
.funnel_tier_div{
    height: 100%;
    // background-color: #e4e1e1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}


.automation_card{
    background-color: #f3f2f2;
    border-radius: 1rem;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}