@import '../../../assets/styles/variables.module.scss';

.container {
    display: flex;
    background-color: $input_background;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;

    width: auto;
    height: 35px;
    font-size: 1.2rem;

}


.select {
    background-color: $input_background;
    border-style: none;
    outline: none;
    overflow: scroll;
    max-width: 200px;


    & option {
        margin: 0rem 0.5rem;
    }
}

.container_mobile {
    height: 40px;
    display: flex;
    background-color: $input_background;
    padding: 0rem 0.5rem;
    border-radius: 0.5rem;
}

.select_mobile {
    background-color: transparent;
    border-style: none;
    outline: none;
    color: white;
    
    & option {
        margin: 0rem 0.5rem;
        color: $gray-primary;
        width: 100%;
    }
}
