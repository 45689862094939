@import "../../../assets/styles/variables.module.scss";

.main {
  position: absolute;
  width: 100%;
  height: 100%;

  // width: fit-content;
  background-color: $seperator_background;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  z-index: 500;
}


.container{
  background-color: white;
  width: auto;
  padding: 5rem;
  border-radius: 5px;
  gap: 1rem;

}

.title {
  font-size: 2rem;
  color: $red-primary;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}


.each_button{
  width: 100px;
  padding: 0.5rem 0rem;
}