.container:hover {
    & td img {
        visibility: visible;
    }
}

.center {
    display: flex;
    justify-content: center;
}

.view {
    cursor: pointer;
    color: green;
    font-weight: 500;
}

.upload {
    cursor: pointer;
    color: red;
    font-weight: 500;
}


.status {
    display: flex;
    justify-content: center;
    min-width: 250px;
    font-weight: 500;

    & img {
        position: absolute;
        visibility: hidden;
        right: 2rem;
        cursor: pointer;
    }
}

.processing {
    color: orange;
}

.request_accepted {
    color: rgb(203, 42, 243);
}

.request_denied {
    color: red
}

.payment_process {
    color: blue
}

.paid {
    color: green;
}



