@import "../../assets/styles/variables.module.scss";

.fixed_height_div{
  min-width: 80vw;
  width: 80vw;

  min-height: 65vh;
  max-height: 82vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow-y: scroll;

}

.automation_fixed_height_div{
  min-width: 65vw;
  width: 65vw;

  min-height: 65vh;
  max-height: 65vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow-y: scroll;

}

.automation_fixed_height_div_short{
  min-width: 65vw;
  width: 65vw;

  min-height: 50vh;
  max-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  overflow-y: scroll;

  // border: 1px solid;
}

.fixed_height_div_space_between{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}


.creative_info_div{
  width: 100%;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  // border: 1px solid green;

}

.creative_info_div_items{
  width: 100%;
  font-size: 1.2rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: $input_background;
  margin-top: 1rem;

  border-radius: 0.5rem 0.5rem 0rem 0rem;
  padding:1rem;

  gap:1.5rem;

}
.creative_info_div_items_column{
  width: 100%;
  font-size: 1.2rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: $input_background;
  margin-top: 1rem;

  border-radius: 0.5rem 0.5rem 0rem 0rem;
  padding:1rem;

  gap:0.5rem;

}

.creative_info_div_items_without_margin{
  width: 100%;
  font-size: 1.2rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: $input_background;
  border-radius: 0.5rem;

  gap:0.5rem;
}

.creative_info_div_items_left{
  width: 77%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.creative_info_div_items_right{
  width: 23%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  
  gap:2rem;


}

.creative_info_div_items_right_left{
  max-width: 30%;
  min-width: 60px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

}

.creative_info_div_items_right_right{
  max-width: 70%;
  min-width: 100px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

}

.creative_info_div_items_right_favicon{
  width: 100%;
  max-height: 40px;
}
.creative_info_div_items_right_image{
  width: 100%;
  max-height: 100px;
}


.flex_row_start_center{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap:0.5rem;


}

.flex_row_start_center_img{
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 0.5rem;

  gap:0.5rem;

}

.flex_row_start_start{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  gap:0.5rem;


}


.padding_row{
  margin: 0rem 0.5rem;
  padding:0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  cursor: pointer;
}

.padding_row:hover{
  margin: 0rem 0.5rem;
  padding:0.5rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;

  cursor: pointer;

}



.main_middle {
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  background-color: $input_background;

}

.border_round{
  border-radius: 0.5rem;
}
.border_round_with_margin{
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.border_top_solid{
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}


.main_middle_mt1 {
  margin-top: 1rem;

}


.middle_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: left;

}

.middle_item_flex_column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  border-radius: 0.5rem;
  gap: 0.2rem;


}


.three_item_div{
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 1rem;

  background-color: white;
  border-radius: 0.5rem;
  border: 0.5rem;

  padding-right: 1rem;

}



.middle_item_flex_row_custom{
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;

}

.checkbox_daily{
  padding-left: 1rem;
  margin-left: 1rem;
}


.middle_item_flex_column_select{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}


.middle_item_label {
  font-size: 1.6rem;
  font-weight: 600;
}

.middle_item_label_type_one {
  font-size: 1.2rem;
  font-style: bold;
  font-weight: 500;
}

.middle_item_label_type_one_automtion {
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 1rem;
}

.half_of_parent_select_div{
  width: 100%;
  // max-width: 20rem;
  max-height: 10rem;
  
  display: block;
  overflow-y: scroll !important;
}

.eachdomaindiv{
  background-color: #d3d3d3;

  margin: 0.3rem;
  padding: 0.5rem;

  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 0.5rem;
}

.eachdomaindiv:hover{
  background-color: $red-secondary;
  margin: 0.3rem;
  padding: 0.5rem;

  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 0.5rem;
}

.custom_select{
  width: 50%;
  padding: 0.5rem;
}




.middle_item_input {
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  width: 100%;
  border-radius: 0.5rem;

  & input {
    width: 100%;
    font-size: 1.4rem;
    border-style: none;
    outline: none;
    background-color: white;
    color: $gray-primary;
  }
  & span {
    position: absolute;
    background-color: white;
    font-size: 1.4rem;
    left: 4rem;
    color: $gray-primary;
  }
}

.middle_item_input_without_padding_rounded{
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;

  & input {
    width: 100%;
    font-size: 1.4rem;
    border-style: none;
    outline: none;
    background-color: white;
    color: $gray-primary;
  }
  & span {
    width: 100%;
    position: absolute;
    background-color: white;
    font-size: 1.4rem;
    left: 4rem;
    color: $gray-primary;
  }
}

.middle_item_input_without_padding{
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.5rem 0rem 0rem 0.5rem;

  & input {
    width: 100%;
    font-size: 1.4rem;
    border-style: none;
    outline: none;
    background-color: white;
    color: $gray-primary;
  }
  & span {
    width: 100%;
    position: absolute;
    background-color: white;
    font-size: 1.4rem;
    left: 4rem;
    color: $gray-primary;
  }
}

.middle_item_input_white {
  display: flex;
  gap: 1rem;
  background-color: white;
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.5rem;

  & input {
    width: 100%;
    font-size: 1.4rem;
    border-style: none;
    outline: none;
    background-color: white;
    color: $gray-primary;
  }
  & span {
    position: absolute;
    background-color: white;
    font-size: 1.4rem;
    left: 4rem;
    color: $gray-primary;
  }
}

.middle_item_input_select_multiple{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: white;
  padding: 1.5rem 1rem;
  width: 100%;

  & input {
    width: 100%;
    font-size: 1.4rem;
    border-style: none;
    outline: none;
    background-color: white;
    color: $gray-primary;
  }
  & span {
    position: absolute;
    background-color: white;
    font-size: 1.4rem;
    left: 4rem;
    color: $gray-primary;
  }
}



.middle_item_input_custom_select{
  width: 100%;
  // min-width: 200px;
  float: 1;
}

.remove_padding {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4rem;

  margin-top: 1rem;

  // border: 1px solid;

}

.formAlertDiv{
  color: $red-primary;
}

.cancel {
  font-size: 1.4rem;
  color: $red-primary;
  font-weight: 400;
  cursor: pointer;
}

.customButton {
  min-width: 80px;
}


.orDiv{
  font-style: bold;
  font-size: 1.4rem;
  font-weight: 600;
  // padding: 0.5rem;
}
