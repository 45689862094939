@import '../../assets/styles/variables.module.scss';

.container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-image: url('../../assets/images/background.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    width: 440px;
    height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.top_container {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $horizontal-line-color;
}

.logo {
    width: 234px;

    img {
        width: 100%;
    }
}

.logo_text{
    font-style: bold;
    font-weight: 800;
    color: $blue-primary;
    font-size: 2.2rem;
    text-align: center;

}

.title {
    font-size: 2rem;
    font-weight: 500;
}

@media screen and (max-width: 500px) {

    .container {
        background-image: unset;
    }

    .main {
        height: 100vh;
        width: 100vw;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
    }
}